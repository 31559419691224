<template>
  <a class="mcr-glossary-link mcr-wiki-glossary-link" :class="{'help-text': isHelpText}" @click.stop="click">
    <slot>{{ text }}</slot>
    <help-icon v-if="isHelpText" :size="18" />
  </a>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';

const HelpIcon = () => import('vue-material-design-icons/HelpCircleOutline');

export default {
  props: {
    text: String,
    naturalId: String,
    isHelpText: {type: Boolean, default: false},
  },
  methods: {
    click(event) {
      event.preventDefault();
      this.$store.commit('setGlossaryWikiNaturalIdState', this.naturalId);
      AnalyticsAmplitudeHandler.trackClickWikiPopupEvent(
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route),
        this.naturalId
      );
      this.$emit('click');
    },
  },
  components: {HelpIcon},
  name: 'mcrWikiGlossaryLink',
};
</script>

<style lang="scss" scoped>
.mcr-wiki-glossary-link.help-text {
  color: $supplemental-text-color;
  display: inline-flex;
  align-items: center;
  &:not(:hover) {
    text-decoration: none;
  }

  &::v-deep {
    .material-design-icon {
      margin-left: 3px;
      margin-bottom: 2px;
    }
  }

  &.multiline {
    display: inline;
    &::v-deep .material-design-icon {
      vertical-align: sub;
    }
  }
}
</style>
